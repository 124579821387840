<template>
  <v-main class="background-image">
    <v-container>
      <v-container class="text-center">
        <span class="firstHalf">Air</span>
        <span class="secondHalf">Guard</span>
      </v-container>
      <v-container fluid>
        <v-row justify="center" align="center">
          <v-col cols="12" md="8" lg="4">
            <v-card-text>
              <p v-if="loginError" style="margin-left: 63px; color: red;" >Username und/oder Passwort falsch</p>
              <v-form >
                <v-text-field
                  label="Username"
                  v-model="username"
                  outlined
                  prepend-icon="mdi-account"
                  color="green"
                  class="custom-text-field"
                  :error="loginError"
                ></v-text-field>
               
                <v-text-field
                  label="Password"
                  v-model="password"
                  outlined
                  prepend-icon="mdi-lock"
                  color="green"
                  type="password"
                  :error="loginError"
                ></v-text-field>
                

                <v-btn @click="loginUser" color="green"  block>
                  Login
                </v-btn>
              </v-form>
            </v-card-text>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

  </v-main>
</template>

<script>

export default {
  data() {
    return {
      username: '',
      password: '',
      controller_ID: '',
      loginError: false
    };
  },
  methods: {
    async loginUser() {
      try {
        const response = await fetch('https://airguard.recyclingheroes.at:5000/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded', 
          },
          body: 'username=' + encodeURIComponent(this.username) + '&password=' + encodeURIComponent(this.password), // Use URL-encoded form data
        });
        if (response.ok) {
          const data = await response.json();
          if (data.Controller_ID && Array.isArray(data.Controller_ID)) {
            this.controller_ID = data.Controller_ID[0];
            this.$store.commit('setLoggedIn', true);
            this.$store.commit('setControllerId', this.controller_ID);
            this.$store.commit('setUsername', this.username);
            this.$router.push({ path: '/daten'});
          }
        } else {
            this.loginError = true;
            this.resetInputs();
          }
      } catch (error) {
        console.error('Fehler beim Abrufen der Daten:', error);
      }
    },
    resetInputs() {
      this.username = '';
      this.password = '';
    },
  }
};
</script>

<style>
  .cen{
    text-align: center;
  }
  .loslegen-button.v-btn {
    background-color: black;
    color: #E1E1E1;
    font-size: 2em;
    opacity: 85%;
    margin-top:7%;

  }
  .firstHalf{
    font-size: 700%;
    font-weight: bold;
    color:#E1E1E1;
    opacity: 75%;
    -webkit-text-stroke: 2px black;
  }
  .secondHalf{
    font-size: 700%;
    color: #315233;
    font-weight: bold;
    text-align: center;
    opacity: 80%;
    -webkit-text-stroke: 2px black;

  }
  .text-center {
    text-align: center;
   
  }
  .background-image {
    background-color: #f7e7ce;
    min-height: 100vh;
  }
  .nav-link{
    text-decoration: none;
    opacity: 1;
  }
  @media (max-width: 600px) {
  .firstHalf,
  .secondHalf{
    font-size: 4em; /* Hier kannst du die gewünschte Schriftgröße für kleine Bildschirme einstellen */
  }
  @media (max-width: 400px) {
    .loslegen-button.v-btn{
      margin-top: 40%;
    }
  }
  .login-background {
  filter: blur(4px);
  }
}
</style>

