import { createStore } from 'vuex';

export default createStore({
  state: {
    userLoggedIn: false,
    controllerId: null,
    username: null
  },
  mutations: {
    setLoggedIn(state, status) {
      state.userLoggedIn = status;
    },
    setControllerId(state, controllerId) {
        state.controllerId = controllerId;
    },
    setUsername(state, username) {
       state.username = username;
    }

  },
  getters: {
    isLoggedIn: state => state.userLoggedIn,
    getControllerId: state => state.controllerId,
    getUsername: state => state.username
  },
});