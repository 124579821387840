<template>
  <v-app>
    <v-app-bar app v-if="isLargeScreen" >
      <!-- Inhalte der ersten Navbar -->
      <v-app-bar-title>
            <router-link to="/"> 
              <v-img :width="180"
            src="images/airguard-logo.png" scale>
            </v-img>
          </router-link>
          </v-app-bar-title>
          <a v-if="$route.name === 'home'"> 
            <v-btn class="normal-button" style="background-color: #418245; margin-right: 10px;" color="white">Login</v-btn>
          </a>
          <router-link v-if="$route.name !== 'home'" to="/daten" class="nav-link" exact-active-class="selected">
            <v-btn class="normal-button">Daten</v-btn>
          </router-link>
          <a href="https://recyclingheroes.shinyapps.io/RecyclingHeroes-Statistiken"  v-if="$route.name !== 'home'" class="nav-link" exact-active-class="selected" >
            <v-btn class="normal-button">Statistik</v-btn>
          </a>
          <router-link v-if="$route.name !== 'home'" to="/about" class="nav-link" exact-active-class="selected">
            <v-btn class="normal-button">About Us</v-btn>
          </router-link>
          <router-link v-if="$route.name !== 'home'" to="/" class="nav-link" exact-active-class="selected">
            <v-btn class="normal-button" style="background-color: #418245; padding: 7px; margin-right: 10px" color="white">Logout</v-btn>
          </router-link>
    </v-app-bar>
    
    <!-- Navbar für kleine Bildschirme -->
    <v-app-bar app v-if="!isLargeScreen">
      <v-app-bar-title>
        <router-link to="/"> 
          <v-img :width="180"
            src="images/airguard-logo.png" scale>
          </v-img>
        </router-link>
      </v-app-bar-title>

      <a v-if="$route.name === 'home'"> 
            <v-btn class="normal-button" style="background-color: #418245; margin-right: 10px;" color="white">Login</v-btn>
      </a>

      <v-menu v-if="$route.name !== 'home'" offset-y>
      <template v-slot:activator="{ props }">
        <v-btn icon v-on="on" v-bind="props">
          <v-icon>mdi-menu</v-icon> <!-- Verwende das gewünschte Burgermenü-Icon (z. B. mdi-menu) -->
        </v-btn>
      </template>
        <!-- Dropdown-Menü-Elemente -->  
        <v-list>
          <router-link v-if="$route.name !== 'home'" to="/daten" class="nav-link">
            <v-list-item>
              <v-list-item-title>Daten</v-list-item-title>
            </v-list-item>
          </router-link>
          <a href="https://recyclingheroes.shinyapps.io/RecyclingHeroes-Statistiken" v-if="$route.name !== 'home'" class="nav-link">
            <v-list-item>
              <v-list-item-title>Statistik</v-list-item-title>
            </v-list-item>
          </a>
          <router-link v-if="$route.name !== 'home'" to="/about" class="nav-link">
            <v-list-item>
              <v-list-item-title>About Us</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/" class="nav-link">
            <v-list-item>
              <v-list-item-title style="font-weight: bold; background-color: #418245; color: white; padding: 10px;border-radius: 5px;">LOGOUT</v-list-item-title>
            </v-list-item>
          </router-link>
        </v-list>
      </v-menu>
    </v-app-bar>

     <!-- Footer für kleine Bildschirme -->
        <v-footer app v-if="!isMidScreen">
          <v-row justify="center">
            <v-col cols="12" class="d-flex justify-center">
              <v-img v-for="(image, index) in images" :key="index" :src="image.src" alt="Bild" width="0" height="100" class="mx-2"></v-img>
           </v-col>
          </v-row>
        </v-footer>
     <!-- Footer für große Bildschirme -->
        <v-footer app v-if="isMidScreen">
           <v-row justify="center">
            <v-col v-for="(image, index) in this.images" :key="index" cols="12" md="2" class="image-col">
              <a :href="getImageLink(index)">
               <v-img :src="image.src" alt="Bild" width="75%" height="100"></v-img>
              </a>
            </v-col>
          </v-row>
        </v-footer>
    <router-view :dataLinkVisible="dataLinkVisible"/>
  </v-app>
</template>
<style>
.selected {
  color: #418245 !important; /* Ändern Sie hier die gewünschte Farbe für den ausgewählten Navigationspunkt */
  text-decoration: underline !important;
  opacity: 1 !important;
}
.nav-link{
  text-decoration: none;
  color:gray;
}
.normal-button{
  font-size: 100%;
}
</style>
<script>

export default {
  name: 'App',
  data() {
    return {
      images: [
        { src: "/images/recycling.png", link: "https://www.recyclingheroes.at" },
        { src: "/images/bee_produce.png", link: "https://www.beeproduced.com/de" },
        { src: "/images/TU_logo.png", link: "https://www.tuwien.at/?pk_campaign=Brand&gclid=EAIaIQobChMIsOmChuX3ggMVk8LVCh1xRQy1EAAYASAAEgKPEvD_BwE" },
        { src: "/images/TGM_Logo.png", link: "http://www.tgm.ac.at" },
        { src: "/images/oead.png", link: "https://oead.at/de/" },
        { src: "/images/Bundesmini.jpg", link: "https://www.bmbwf.gv.at" },
        
        
        ],
      isLargeScreen: window.innerWidth >= 600,
      isMidScreen: window.innerWidth >= 1000, // Beispiel für große Bildschirme, passe die Breite nach Bedarf an
      dataLinkVisible: false
    };
  },
  mounted() {
    window.addEventListener('resize', this.checkScreenSize);
  },
  unmounted() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      this.isLargeScreen = window.innerWidth >= 600,
      this.isMidScreen = window.innerWidth >= 1000 // Beispiel für große Bildschirme, passe die Breite nach Bedarf an
    },
    getImageLink(index) {
      // Hier kannst du die Logik implementieren, um den Link für jedes Bild zu bestimmen.
      return this.images[index].link;
    }
  }
}
</script>
